'use client'
import { Mic, OndemandVideo } from '@mui/icons-material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Grid } from '@mui/material'
import Image from 'next/image'
import Link from 'next/link'
import { BlogType } from 'src/utils/GhostAPI'

function sliceStringKeepLastWord(inputString: string, maxLength: number) {
  if (inputString.length <= maxLength) {
    return inputString
  }

  let lastSpaceIndex = inputString.lastIndexOf(' ', maxLength)

  if (lastSpaceIndex === -1) {
    lastSpaceIndex = inputString.indexOf(' ', maxLength)
  }

  if (lastSpaceIndex !== -1) {
    return inputString.slice(0, lastSpaceIndex) + '...'
  }

  return inputString.slice(0, maxLength)
}

const BlogCardsSection = ({
  blogs,
  loading
}: {
  blogs: BlogType[]
  loading: boolean
}) => {
  return (
    <Grid container rowSpacing={4} columnSpacing={2}>
      {loading ? (
        <Grid item xs={12} className="flex justify-center">
          <Image
            src="/img/blogs/LoadingBlue.svg"
            alt="Loading"
            width={100}
            height={100}
          />
        </Grid>
      ) : (
        blogs &&
        blogs.map((blog: any) => (
          <Grid key={blog.id} item lg={4} md={6} sm={12} className="font-sora">
            <Link
              href={
                blog.external || blog.media ? blog.url : `/blog/${blog.slug}`
              }
              target={blog.external || blog.media ? '_blank' : ''}
              className="group block overflow-hidden rounded-lg border-[1px] border-[#E6E6E6E6] p-3">
              <div className="2xl:h-[240px] xl:h-[200px] lg:h-[180px] h-[240px] overflow-hidden flex items-center rounded-lg border-[1px] border-[#E6E6E6E6]">
                <Image
                  src={
                    blog.image
                      ? blog.image
                      : '/img/blogs/DefaultPlaceholder.png'
                  }
                  width={400}
                  height={240}
                  alt={blog.title ? blog.title : ''}
                  className="w-full group-hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
              <div className="flex flex-col justify-between min-h-[220px] pt-4">
                <div className="flex flex-col">
                  <div className="flex justify-between items-center">
                    {blog.external ? (
                      <div className="inline-flex items-center self-start bg-[#e1e8f7] px-3 h-[30px] rounded-[15px]">
                        <Image
                          src="/img/blogs/PressReleaseIcon.svg"
                          alt="Press Release"
                          width={15}
                          height={15}
                        />
                        <span className="ml-2 text-[14px] text-[#202020]">
                          Press Release
                        </span>
                      </div>
                    ) : blog.media ? (
                      <div className="inline-flex items-center self-start bg-[#196E8226] px-3 h-[30px] rounded-[15px]">
                        {blog.tags.includes('video') ? (
                          <OndemandVideo
                            sx={{ color: '#196E82', fontSize: '20px' }}
                          />
                        ) : (
                          <Mic sx={{ color: '#196E82', fontSize: '20px' }} />
                        )}
                        <span className="ml-2 text-[14px] text-[#202020]">
                          {blog.tags.includes('video') ? 'Video' : 'Podcast'}
                        </span>
                      </div>
                    ) : (
                      <div className="inline-flex items-center self-start bg-[#def7dc] px-3 h-[30px] rounded-[15px]">
                        <Image
                          src="/img/blogs/ArticlesIcon.svg"
                          alt="Article"
                          width={15}
                          height={15}
                        />
                        <span className="ml-2 text-[14px] text-[#202020]">
                          Article
                        </span>
                      </div>
                    )}
                    <div className="text-[14px] text-[#444]">{blog.date}</div>
                  </div>
                  <div className="mt-3 md:text-[21px] text-[18px] font-semibold">
                    {sliceStringKeepLastWord(blog.title, 60)}
                  </div>
                </div>

                {blog.external ? (
                  <div className="flex justify-between flex-col sm:flex-row pt-3 sm:items-center flex-wrap">
                    <div className="flex items-center">
                      <Image
                        src={blog.icon}
                        alt={blog.publisher}
                        height={30}
                        width={30}
                        className="rounded"
                      />
                      <span className="ml-2 text-[14px] mb-[-3px]">
                        {blog.publisher}
                      </span>
                    </div>
                    <div className="flex items-center bg-[#EDEDED] py-2 px-4 sm:mt-0 mt-2">
                      <span className="font-semibold mr-2">read now</span>
                      <OpenInNewIcon />
                    </div>
                  </div>
                ) : blog.media ? (
                  <div className="flex justify-between flex-col sm:flex-row pt-3 sm:items-center flex-wrap">
                    <div className="flex items-center">
                      <Image
                        src={blog.icon}
                        alt={blog.publisher}
                        height={30}
                        width={30}
                        className="rounded"
                      />
                      <span className="ml-2 text-[14px]">{blog.publisher}</span>
                    </div>
                    <div className="flex items-center bg-[#EDEDED] py-2 px-4 sm:mt-0 mt-2">
                      <span className="font-semibold mr-2">
                        {blog.tags.includes('video') ? 'watch' : 'listen'} now
                      </span>
                      <OpenInNewIcon />
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-between flex-col sm:flex-row pt-3 sm:items-center flex-wrap">
                    <div className="flex items-center">
                      <Image
                        src="/favicon.ico"
                        alt="Logo Blog"
                        height={26}
                        width={26}
                      />
                      <span className="ml-2 text-[14px]">Loop</span>
                    </div>
                    <div className="flex items-center bg-[#EDEDED] py-2 px-4 sm:mt-0 mt-2">
                      <span className="font-semibold mr-2">read now</span>
                      <ArrowForwardIcon />
                    </div>
                  </div>
                )}
              </div>
            </Link>
          </Grid>
        ))
      )}
    </Grid>
  )
}

export default BlogCardsSection
