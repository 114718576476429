// @ts-ignore
import GhostContentAPI from '@tryghost/content-api'
// @ts-ignore
import moment from 'moment'

export type BlogType = {
  id: string
  title: string
  image?: string
  slug: string
  date?: string
  tags?: string[]
  external?: boolean
  media?: boolean
  mediaURL?: string
  url?: string
  icon?: string
  publisher?: string
  content?: string
  metaDescription?: string
  metaTitle?: string
  ogImage?: string
}

const api = new GhostContentAPI({
  url: process.env.NEXT_PUBLIC_GHOST_API_URL,
  key: process.env.NEXT_PUBLIC_GHOST_CONTENT_API,
  version: 'v5.0'
})

export async function getAllGhostPosts(): Promise<{
  status: number
  error?: any
  posts?: BlogType[]
}> {
  try {
    let allPosts = await api.posts.browse({
      include: 'tags',
      fields: `id,title,feature_image,published_at,slug,excerpt,html`
    })

    let loopPosts: any[] = []

    allPosts.forEach((post: any) => {
      loopPosts.push(post)
    })

    let filteredPosts: BlogType[] = loopPosts.map((post) => {
      let external = false
      let media = false

      for (let tag of post.tags) {
        if (tag.name?.toLowerCase() === 'external') {
          external = true
          break
        } else if (tag.name?.toLowerCase() === 'podcast' || tag.name?.toLowerCase() === 'video') {
          media = true
          break
        }
      }

      const parser = new DOMParser()
      const doc = parser.parseFromString(post.html, 'text/html')

      let href = ''
      let title = ''
      let icon = ''
      let publisher = ''
      let image = ''

      if (external || media) {
        const bookmarkContainer: any = doc.querySelector(
          '.kg-bookmark-container'
        )

        if (bookmarkContainer) {
          href = bookmarkContainer.getAttribute('href')
          title =
            bookmarkContainer.querySelector('.kg-bookmark-title').textContent
          icon = bookmarkContainer
            .querySelector('.kg-bookmark-icon')
            .getAttribute('src')

          const urlObject = new URL(href)
          const domain = `${urlObject.protocol}//${urlObject.hostname}`

          const faviconUrl = `https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${domain}&size=128`
          icon = faviconUrl
          publisher = bookmarkContainer.querySelector(
            '.kg-bookmark-author'
          ).textContent
          image = bookmarkContainer
            .querySelector('.kg-bookmark-thumbnail img')
            .getAttribute('src')
        } else if (post.excerpt) {
          const urlRegex = /(https?:\/\/[^\s#]+)/
          const urls = post.excerpt.match(urlRegex)
          if (urls[0]) {
            href = urls[0]

            const urlObject = new URL(href)
            const domain = `${urlObject.protocol}//${urlObject.hostname}`
            const websiteName = urlObject.hostname

            const faviconUrl = `https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${domain}&size=128`

            publisher = websiteName
            icon = faviconUrl
          }
        }
      }

      let mediaURL = ''
      if (media) {
        mediaURL =
          doc
            .querySelector('.kg-audio-player-container audio')
            ?.getAttribute('src') || ''
      }

      const now = moment()
      const blogDate = moment(post.published_at)
      const dayDiff = now.diff(blogDate, 'days')

      return {
        id: post.id,
        title: post.title ? post.title : title ? title : '',
        image: post.feature_image ? post.feature_image : image ? image : '',
        date:
          dayDiff > 7
            ? moment(post.published_at).format('DD MMM, YYYY')
            : now.to(blogDate),
        slug: post.slug,
        external: external,
        media: media,
        mediaURL: mediaURL,
        url: href,
        icon: icon ? icon : '/img/blogs/FoodOnDemandBlog.png',
        publisher: publisher,
        tags: post.tags.map((tag: any) => tag.name?.toLowerCase())
      }
    })

    return {
      status: 200,
      posts: filteredPosts
    }
  } catch (error) {
    return {
      status: 400,
      error: error
    }
  }
}

export async function getGhostPostWithSlug(slug: string) {
  try {
    const url = new URL(
      `${process.env.NEXT_PUBLIC_GHOST_API_URL}/ghost/api/content/posts/slug/${slug}/`
    )

    url.search = new URLSearchParams({
      include: 'tags',
      fields:
        'id,title,feature_image,slug,published_at,html,meta_description,meta_title,og_image',
      key: process.env.NEXT_PUBLIC_GHOST_CONTENT_API || ''
    }).toString()

    const response = await fetch(url)
    const allPostsWithSlug = await response.json()

    if (
      !allPostsWithSlug ||
      !allPostsWithSlug.posts ||
      !allPostsWithSlug.posts[0]
    )
      return {
        status: 400,
        error: "Couldn't fetch post"
      }

    const post = allPostsWithSlug.posts[0]

    let filteredPost: BlogType = {
      id: post.id,
      title: post.title,
      image: post.feature_image,
      slug: post.slug,
      date: moment(post.published_at).format('DD MMMM, YYYY'),
      content: post.html,
      metaDescription: post.meta_description,
      metaTitle: post.meta_title,
      ogImage: post.og_image
    }

    return {
      status: 200,
      post: filteredPost
    }
  } catch (error) {
    return {
      status: 400,
      error: error
    }
  }
}

export async function getAllSitemapGhostPosts(): Promise<{
  status: number
  error?: any
  posts?: BlogType[]
}> {
  try {
    const url = new URL(
      `${process.env.NEXT_PUBLIC_GHOST_API_URL}/ghost/api/content/posts/`
    )

    url.search = new URLSearchParams({
      include: 'tags',
      fields: 'id,title,published_at,slug',
      key: process.env.NEXT_PUBLIC_GHOST_CONTENT_API || ''
    }).toString()

    const response = await fetch(url)
    const allPosts = await response.json()

    let loopPosts: any[] = []

    allPosts?.posts?.forEach((post: any) => {
      loopPosts.push(post)
    }) || []

    let filteredPosts: BlogType[] = loopPosts
      .filter(
        (post) =>
          !post.tags.some(
            (tag: any) => tag.name?.toLowerCase() === 'external' || tag.name?.toLowerCase() === 'podcast' || tag.name?.toLowerCase() === 'video'
          )
      )
      .map((post) => ({
        id: post.id,
        title: post.title,
        date: post.published_at,
        slug: post.slug
      }))

    return {
      status: 200,
      posts: filteredPosts
    }
  } catch (error) {
    return {
      status: 400,
      error: error
    }
  }
}
